// import { Link } from "react-router-dom";
import { Banner } from "./component/Banner";
// import { ImageSlider } from "./component/ImageSlider";
import "./css/HomePage.css"

export const HomePage = () => {
  return (
    <div className='mt-5'>
      <Banner />
      <div style={{height: '240px'}}></div>

      {/* <main id='home-content'>
          <section id='movie-section' className='home container' style={{background: 'red'}}>
            <h2>Popular Movies</h2>
            <div className=''>
              <ImageSlider /> 
            </div>

            <Link to='/movies'>
              <button type="button" className="btn btn-outline-success" > More Movies </button> 
            </Link>

            <div style={{background: 'black', height: '10px'}}/>

            <h2>Released in 2020 - Now</h2>
            <div className=''>
            <ImageSlider />
            </div>

            <Link to='/movies'>
              <button type="button" className="btn btn-outline-success" > More Movies </button> 
            </Link>

            <div style={{background: 'black', height: '10px'}}/>

            <h2>Released in 2010 - 2020</h2>
            <div className=''>
              <ImageSlider /> 
            </div>

            <Link to='/movies'>
              <button type="button" className="btn btn-outline-success" > More Movies </button> 
            </Link>

            <div style={{background: 'black', height: '10px'}}/>

            <h2>Released in 2000 - 2010</h2>
            <div className=''>
              <ImageSlider /> 
            </div>

            <Link to='/movies'>
              <button type="button" className="btn btn-outline-success" > More Movies </button> 
            </Link>
          </section>

          <br/>

          <section id='actor-section' className='home container' style={{background: 'blue'}}>
            <h2>Popular Actors</h2>
            <div className=''>
              <ImageSlider /> 
            </div>
            <Link
              to='/actors' >
              <button  type="button" className="btn btn-outline-success" > More Actors </button> 
            </Link>
            
            <div style={{background: 'black', height: '10px'}}/>

            <h2>Popular Actresses</h2>
            <div className=''>
              <ImageSlider /> 
            </div>
            <Link
              to='/actors' >
              <button  type="button" className="btn btn-outline-success" > More Actors </button> 
            </Link>
          </section>

          <br />

          <section id='channels-section' className='home container' style={{background: 'pink'}}>
            <h2>Channels</h2>
            <div>
                <ImageSlider /> 
            </div>
          </section>
      </main> */}
    </div> 
  );
};
