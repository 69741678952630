import "./App.css";
import { Navbar } from "./layouts/NavbarAndFooter/Navbar";
import { SearchResultsPage } from "./layouts/SearchResultsPage/SearchResultsPage";
// import { AboutUsPage } from "./layouts/AboutUsPage/AboutUsPage";
// import { ActorsPage } from "./layouts/ActorsPage/ActorsPage";
import { HomePage } from "./layouts/HomePage/HomePage";
import { MoviesPage } from "./layouts/MoviesPage/MoviesPage";
import { Redirect, Route, Switch } from "react-router-dom";
import { Footer } from "./layouts/NavbarAndFooter/Footer";
import { MoviePage } from "./layouts/MoviePage/MoviePage";
// import { ActorPage } from "./layouts/ActorPage/ActorPage";

export const App: React.FC = () => {
  return (
    <div className='d-flex flex-column min-vh-100' style={{background: 'black'}} >
      <Navbar />
      <div className='flex-grow-1'>
        <Switch>
          <Route path='/search'>
            <SearchResultsPage />
          </Route>

          <Route path='/' exact>
            <Redirect to='/home' />
          </Route>

          <Route path='/home' exact>
            <HomePage />
          </Route>

          <Route path='/movies'>
            <MoviesPage />
          </Route>

          <Route path='/movie/:movieId'>
            <MoviePage />
          </Route>

           {/* <Route path='/actors'>
            <ActorsPage />
          </Route> 

          <Route path='/actor/:actorId'>
            <ActorPage />
          </Route> 

          <Route path='/aboutus'>
            <AboutUsPage />
          </Route>            */}
        </Switch>
       
      </div>
      <Footer />
    </div>
  );
};
