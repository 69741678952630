import "./css/MovieCardProfile.css";
import MovieModel from "../../../models/MovieModel";
import { useCallback, useEffect, useState } from "react";
import { Rating } from "@mui/material";
import { MoviePoster } from "./MoviePoster";

export const MovieCardProfile: React.FC<{ movie: MovieModel }> = (props) => {
  const [rating, setRating] = useState<number>(0);

  const fetchRating = useCallback(async () => {
    try {
      const url: string = `${process.env.REACT_APP_API_URI}/api/ratings/movie/${props.movie.movie_id}/ratings/average`;
      const response = await fetch(url);
      const data = await response.text();
      const apiRating: number = parseFloat(data);
      setRating(apiRating);
    } catch (error) {
      console.error("Error fetching rating:", error);
    }
  }, [props.movie.movie_id]);

  useEffect(() => {
    fetchRating();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRating]);

  const handleRatingChange = async (
    event: React.SyntheticEvent,
    value: number | null
  ) => {
    if (value !== null) {
      setRating(value);
    }

    try {
      const url: string = `${process.env.REACT_APP_API_URI}/api/ratings/movie/add-rating`;
      const payload = {
        movieId: props.movie.movie_id,
        ratingValue: value,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        await fetchRating();
      } else {
        console.error("Failed to update rating:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  return (
    <div className='movie-card'>
      <div className='movie-card-thumbnail'>
        <MoviePoster movie={props.movie} />
      </div>
      <div className='movie-card-body'>
        <div className='movie-card-text'>
          <h2 className='movie-card-title'>{props.movie.title}</h2>
          <span className='rating-stars'><Rating name="half-rating" value={rating} precision={0.5} style={{ opacity: 1, color: "#ffda00" }} onChange={handleRatingChange}/></span>
          <div className='movie-tags'>
            <span>{props.movie.episodes.length} Episodes,</span>
            <span className="movie-tags-link">{isNaN(props.movie.release_year) || props.movie.release_year == null ? '' : `${props.movie.release_year},`}</span>
            {/* <span className="movie-tags-link">Placeholder</span> */}
          </div>
        </div>
      </div>
    </div>
  );

};
