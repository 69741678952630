import "./css/EpisodeSlider.css";
import React, { useState, useEffect } from "react";
import MovieModel from "../../../models/MovieModel";
import { GrCaretNext, GrCaretPrevious } from "react-icons/gr";

interface EpisodeSliderProps {
  movie: MovieModel;
  episodesGroupLength: number;
  activeIndex: number;
  handleEpisodeButtonClick: (episodeIndex: number, videoId: string) => void;
}

export const EpisodeSlider: React.FC<EpisodeSliderProps> = ({
  episodesGroupLength,
  movie,
  activeIndex,
  handleEpisodeButtonClick,
}) => {
  const [currentGroupStartIndex, setCurrentGroupStartIndex] = useState<number>(0);
  const totalEpisodes = movie?.episodes?.length ?? 0;

  const currentGroupStartIndexKey = `movie_episode_${movie.movie_poster}_videoId`;

  useEffect(() => {
    const storedCurrentGroupStartIndexKey = localStorage.getItem(currentGroupStartIndexKey);
    if (storedCurrentGroupStartIndexKey) {
      setCurrentGroupStartIndex(parseInt(storedCurrentGroupStartIndexKey));
    }
  }, [currentGroupStartIndexKey]);

  useEffect(() => {
    localStorage.setItem(currentGroupStartIndexKey, currentGroupStartIndex.toString());
  }, [currentGroupStartIndex, currentGroupStartIndexKey]);

  // Handle moving to the next group
  const handleNext = () => {
    const newActiveIndex = activeIndex + 1;

    if (newActiveIndex < totalEpisodes) {
      const newGroupStartIndex = Math.floor(newActiveIndex / episodesGroupLength) * episodesGroupLength;
      setCurrentGroupStartIndex(newGroupStartIndex);
      const videoId = movie.episodes[newActiveIndex]?.video_Id ?? "";
      handleEpisodeButtonClick(newActiveIndex, videoId);
    }
  };

  // Handle moving to the previous group
  const handlePrevious = () => {
    const newActiveIndex = activeIndex - 1;

    if (newActiveIndex >= 0) {
      const newGroupStartIndex = Math.floor(newActiveIndex / episodesGroupLength) * episodesGroupLength;
      setCurrentGroupStartIndex(newGroupStartIndex);
      const videoId = movie.episodes[newActiveIndex]?.video_Id ?? "";
      handleEpisodeButtonClick(newActiveIndex, videoId);
    }
  };

  // Generate the current group of episodes
  const generateEpisodeGroup = (): React.ReactNode => {
    const episodesToShow = movie?.episodes.slice(
      currentGroupStartIndex,
      currentGroupStartIndex + episodesGroupLength
    );

    return (
      <div className="slider-container">
        <div className="slider-navigation-left">
          <button
            onClick={handlePrevious}
            disabled={activeIndex === 0}
            className="btn btn-outline-success"
          >
            <GrCaretPrevious />
          </button>
        </div>
        <ul className="list-group list-group-horizontal gap-3">
          {episodesToShow?.map((episode, i) => {
            const episodeIndex = currentGroupStartIndex + i;
            return (
              <li key={i}>
                <button
                  className={`mt-2 list-group-item list-group-item-action rounded${
                    episodeIndex === activeIndex ? " active" : ""
                  }`}
                  type="button"
                  onClick={() =>
                    handleEpisodeButtonClick(episodeIndex, episode.video_Id ?? "")
                  }
                >
                  Episode {episode.episode_number}
                </button>
              </li>
            );
          })}
        </ul>
        <div className="slider-navigation-right">
          <button
            onClick={handleNext}
            disabled={activeIndex == totalEpisodes - 1}
            className="btn btn-outline-success"
          >
            <GrCaretNext />
          </button>
        </div>
      </div>
    );
  };

  return <div>{generateEpisodeGroup()}</div>;
};
