import "./css/Navbar.css";
import React, { useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { Link, useHistory, useLocation } from "react-router-dom";

export const Navbar = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const location = useLocation();
  const searchRef = useRef<HTMLFormElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null); 

  useEffect(() => {
    if (!location.pathname.startsWith("/movie")) {
      localStorage.removeItem("currentMoviesPage");
      localStorage.removeItem("selectedLetter");
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setIsSearchOpen(false);
      }

      if (navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
          handleNavLinkClick();
      }

      if (location.pathname === "/search") {
        setIsSearchOpen(true);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [location.pathname]);

  const handleToggleSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsSearchOpen((prevState) => !prevState);
  };

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();
    queryParams.append("value", searchValue);
    const searchUrl = `/search?${queryParams.toString()}`;
    history.push(searchUrl);
    handleNavLinkClick();
  };

  const handleNavLinkClick = () => {
    const bootstrapNavbarCollapse = document.querySelector('#navbarCollapse') as HTMLElement;
    if (bootstrapNavbarCollapse && bootstrapNavbarCollapse.classList.contains('show')) {
      bootstrapNavbarCollapse.classList.remove('show');
    }
  };

  const links = [
    { label: "Home", path: "/home" },
    { label: "Movies", path: "/movies" },
    // { label: "Actors", path: "/actors" },
    // { label: "About Us", path: "/aboutus" },
  ];

  const renderedLinks = links.map((link) => {
    const navLinkClass = `nav-link ${
      location.pathname === link.path ? "active" : ""
    }`;
    const ariaCurrentAttr =
      location.pathname === link.path ? "page" : undefined;

    return (
      <li className='nav-item' key={link.label}>
        <Link
          className={navLinkClass}
          aria-current={ariaCurrentAttr}
          to={link.path}
          onClick={handleNavLinkClick} 
        >
          {link.label}
        </Link>
      </li>
    );
  });

  return (
    <nav className='navbar navbar-expand-md navbar-dark fixed-top bg-dark'>
      <div className='container-fluid' ref={navbarRef}>
        <div style={{paddingLeft: '10px'}}>
          <Link
            className='navbar-brand'
            to='/home'>
            <span style={{color: '#ffda00'}}>tala</span><span style={{color: 'rgb(88, 0, 0)', textShadow: '-1px -1px 0 #777171, 1px -1px 0 #777171, -1px 1px 0 #777171, 1px 1px 0 #777171'}}>Retro</span>
          </Link>
        </div>

        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarCollapse'
          aria-controls='navbarCollapse'
          aria-expanded='false'
          aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon'></span>
        </button>
        
        <div className='collapse navbar-collapse' id='navbarCollapse'>
          <ul className='navbar-nav ms-auto mb-2 mb-md-0'>{renderedLinks}</ul>
          {isSearchOpen ? (
            <form
              className='d-flex'
              role='search'
              onSubmit={handleSearchSubmit}
              ref={searchRef}>
              <input
                className='form-control me-2'
                type='search'
                placeholder='Search'
                aria-label='Search'
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <button className='btn btn-outline-success' type='submit'>
                <BsSearch />
              </button>
            </form>
          ) : (
            <button
              className='btn btn-outline-success'
              type='button'
              onClick={handleToggleSearch}>
              Search <BsSearch />
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};
