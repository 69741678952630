import { useState, useEffect } from "react";
import MovieModel from "../../../models/MovieModel";

export const MoviePoster: React.FC<{ movie: MovieModel }> = (props) => {
  const [imageSrc, setImageSrc] = useState<string>(
    `https://img.youtube.com/vi/${props.movie.movie_poster}/maxresdefault.jpg`
  );

  useEffect(() => {
    const checkImageExists = (imageUrl: string) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = function () {
        if (img.width === 120) {
          setImageSrc(
            `https://img.youtube.com/vi/${props.movie.movie_poster}/mqdefault.jpg`
          );
        }
      };
    };

    checkImageExists(imageSrc);
  }, [imageSrc, props.movie.movie_poster]);

  return (
    <img
      src={imageSrc}
      alt='Movie Poster'
    />
  );
};

export default MoviePoster;
