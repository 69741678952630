import { IoArrowBackCircleOutline } from "react-icons/io5";
// import { Link } from "react-scroll";
import { Link} from "react-router-dom";

import "../component/css/Banner.css"

export const Banner = () => {
  return (
    <header id='banner'>
      <div className='inner'>
          <h1><span style={{color: '#ffda00'}}>tala</span><span style={{color: 'rgb(88, 0, 0)', textShadow: '-1px -1px 0 #777171, 1px -1px 0 #777171, -1px 1px 0 #777171, 1px 1px 0 #777171'}}>Retro</span></h1>
          <p>Connecting you to Congolese Theatre</p>

        {/* <Link to='home-content' smooth={true} duration={500} className=''> */}
        <Link to="/movies">
          <IoArrowBackCircleOutline style={{ fontSize: '48px', color: 'white', transform: 'rotate(-90deg)' }}/>
        </Link>
      </div>
    </header>
  );
};
