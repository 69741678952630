import { useEffect, useState } from "react";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import { Link, useLocation } from "react-router-dom";
import MovieModel from "../../models/MovieModel";
import ActorModel from "../../models/ActorModel";
import MovieEpisodeModel from "../../models/MovieEpisodeModel";
import { Pagination } from "../Utils/Pagination";
import {
  isDesktop,
  isMobileOnly,
  isTablet,
} from "react-device-detect";
import { MovieCard } from "../MoviesPage/components/MovieCard";
import { ActorCard } from "../ActorsPage/components/ActorCard";

type SearchResult = MovieModel | ActorModel;

export const SearchResultsPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get("value");

  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState(null);

  const [resultsPerPage, setResultsPerPage] = useState(35);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(searchResults.length / resultsPerPage);
  const startIndex = (currentPage - 1) * resultsPerPage;
  const endIndex = startIndex + resultsPerPage;
  const currentSearchResults = searchResults.slice(startIndex, endIndex);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  useEffect(() => {
    const fetchSearchMoviesResults = async () => {
      const url: string = `${process.env.REACT_APP_API_URI}/api/movies/search/title?value=${searchValue}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const responseJson = await response.json();

      const moviesResult: MovieModel[] = responseJson.map((movieData: any) => {
        const loadedEpisodes: MovieEpisodeModel[] = movieData.episodes
          .map((episodeData: any) => new MovieEpisodeModel(
            episodeData.episode_id,
            episodeData.episodeNumber,
            episodeData.title.toUpperCase(),
            episodeData.videoId,
            episodeData.description
          ))
          .sort((a: MovieEpisodeModel, b: MovieEpisodeModel) => a.episode_number - b.episode_number);
        return new MovieModel(
          movieData.movieId,
          movieData.title.toUpperCase(),
          movieData.uploadDate,
          movieData.releaseYear,
          movieData.genre,
          movieData.moviePoster,
          loadedEpisodes,
          movieData.uploadChannel,
          movieData.dateModified
        );
      });
      return moviesResult;
    };

    const fetchSearchActorsResults = async () => {
      const url: string = `${process.env.REACT_APP_API_URI}/api/actors/search/name?value=${searchValue}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const responseJson = await response.json();

    const actorsResult: ActorModel[] = responseJson.map((actorData: any) =>
      new ActorModel(
        actorData.actorId,
        actorData.actorName.toUpperCase(),
        actorData.gender
      )
    );
    
      return actorsResult;
    };

    const fetchData = async () => {
      try {
        const moviesResult = await fetchSearchMoviesResults();
        const actorsResult = await fetchSearchActorsResults();

        const tempSearchResults: SearchResult[] = [
          ...moviesResult,
          ...actorsResult,
        ];

        tempSearchResults.sort((a, b) => {
          if (
            (a instanceof MovieModel && b instanceof ActorModel) ||
            (a instanceof ActorModel && b instanceof MovieModel)
          ) {
            return a instanceof MovieModel ? 1 : -1;
          } else {
            const aSortValue =
              a instanceof MovieModel
                ? (a as MovieModel).title
                : (a as ActorModel).actor_Name;
            const bSortValue =
              b instanceof MovieModel
                ? (b as MovieModel).title
                : (b as ActorModel).actor_Name;
            return aSortValue.localeCompare(bSortValue);
          }
        });

        setSearchResults(tempSearchResults);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        setHttpError(error.message);
      }
    };

    fetchData();
    window.scroll(0, 0);
  }, [searchValue, resultsPerPage]);

  useEffect(() => {
    const handleResize = () => {
      const isFourMoviesPerPage =
        window.innerWidth > 577 && window.innerWidth < 1440;
      const isThreeMoviesPerPage = window.innerWidth <= 576;

      let updatedResultsPerPage = 35;

      if (isFourMoviesPerPage) {
        if (isTablet) {
          updatedResultsPerPage = 30;
        } else {
          updatedResultsPerPage = 32;
        }
      } else if (isThreeMoviesPerPage) {
        updatedResultsPerPage = 30;
      }

      setResultsPerPage(updatedResultsPerPage);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (searchValue === "") {
    return (
      <div className='container-fluid'>
        <br />
        <h1 className='display-6 mt-5' style={{ color: "white" }}>
          No search value has been entered
        </h1>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center vh-100'>
        <SpinnerLoading />
      </div>
    );
  }

  if (httpError) {
    return (
      <div className='container m-5'>
        <p>{httpError}</p>
      </div>
    );
  }

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {/* Desktop */}
      {isDesktop && (
        <>
          <div className='d-none desktop d-xl-block'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Search results for : {searchValue}
              </h1>
              <div className='row justify-content-start'>
                {currentSearchResults.map((result, index) => (
                  <div key={index} className='col-2 mt-2 mx-3'>
                    {result instanceof ActorModel ? (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/actor/${result.actor_id}/${result.actor_Name}`,
                          state: { actor: result },
                        }}
                        onClick={handleClick}>
                        <ActorCard actor={result} size='15rem' />
                      </Link>
                    ) : (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/movie/${result.movie_poster}`,
                          state: { movie: result },
                        }}
                        onClick={handleClick}>
                        <MovieCard movie={result} size='15rem' />
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none desktop d-lg-block d-xl-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Search results for : {searchValue}
              </h1>
              <div className='row justify-content-start'>
                {currentSearchResults.map((result, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    {result instanceof ActorModel ? (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/actor/${result.actor_id}/${result.actor_Name}`,
                          state: { actor: result },
                        }}
                        onClick={handleClick}>
                        <ActorCard actor={result} size='13rem' />
                      </Link>
                    ) : (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/movie/${result.movie_poster}`,
                          state: { movie: result },
                        }}
                        onClick={handleClick}>
                        <MovieCard movie={result} size='13rem' />
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none desktop d-md-block d-lg-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Search results for : {searchValue}
              </h1>
              <div className='row justify-content-start'>
                {currentSearchResults.map((result, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    {result instanceof ActorModel ? (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/actor/${result.actor_id}/${result.actor_Name}`,
                          state: { actor: result },
                        }}
                        onClick={handleClick}>
                        <ActorCard actor={result} size='10rem' />
                      </Link>
                    ) : (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/movie/${result.movie_poster}`,
                          state: { movie: result },
                        }}
                        onClick={handleClick}>
                        <MovieCard movie={result} size='10rem' />
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none desktop d-sm-block d-md-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Search results for : {searchValue}
              </h1>
              <div className='row justify-content-start'>
                {currentSearchResults.map((result, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    {result instanceof ActorModel ? (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/actor/${result.actor_id}/${result.actor_Name}`,
                          state: { actor: result },
                        }}
                        onClick={handleClick}>
                        <ActorCard actor={result} size='8.5rem' />
                      </Link>
                    ) : (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/movie/${result.movie_poster}`,
                          state: { movie: result },
                        }}
                        onClick={handleClick}>
                        <MovieCard movie={result} size='8.5rem' />
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='desktop d-sm-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Search results for : {searchValue}
              </h1>
              <div className='row justify-content-start'>
                {currentSearchResults.map((result, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    {result instanceof ActorModel ? (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/actor/${result.actor_id}/${result.actor_Name}`,
                          state: { actor: result },
                        }}
                        onClick={handleClick}>
                        <ActorCard actor={result} size='8rem' />
                      </Link>
                    ) : (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/movie/${result.movie_poster}`,
                          state: { movie: result },
                        }}
                        onClick={handleClick}>
                        <MovieCard movie={result} size='8rem' />
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-5 mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </>
      )}

      {/* Tablet*/}
      {isTablet && (
        <>
          <div className='d-none tablet d-lg-block d-xl-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Search results for : {searchValue}
              </h1>
              <div className='row justify-content-start'>
                {currentSearchResults.map((result, index) => (
                  <div key={index} className='col-2 mt-2 mx-3'>
                    {result instanceof ActorModel ? (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/actor/${result.actor_id}/${result.actor_Name}`,
                          state: { actor: result },
                        }}
                        onClick={handleClick}>
                        <ActorCard actor={result} size='13rem' />
                      </Link>
                    ) : (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/movie/${result.movie_poster}`,
                          state: { movie: result },
                        }}
                        onClick={handleClick}>
                        <MovieCard movie={result} size='13rem' />
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none tablet d-md-block d-lg-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Search results for : {searchValue}
              </h1>
              <div className='row justify-content-start'>
                {currentSearchResults.map((result, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    {result instanceof ActorModel ? (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/actor/${result.actor_id}/${result.actor_Name}`,
                          state: { actor: result },
                        }}
                        onClick={handleClick}>
                        <ActorCard actor={result} size='11rem' />
                      </Link>
                    ) : (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/movie/${result.movie_poster}`,
                          state: { movie: result },
                        }}
                        onClick={handleClick}>
                        <MovieCard movie={result} size='11rem' />
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </>
      )}

      {/* Mobile*/}
      {isMobileOnly && (
        <>
          <div
            className='d-none mobile d-md-block d-lg-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Search results for : {searchValue}
              </h1>
              <div className='row justify-content-start'>
                {currentSearchResults.map((result, index) => (
                  <div key={index} className='col-2 mt-2 mx-1'>
                    {result instanceof ActorModel ? (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/actor/${result.actor_id}/${result.actor_Name}`,
                          state: { actor: result },
                        }}
                        onClick={handleClick}>
                        <ActorCard actor={result} size='8.5rem' />
                      </Link>
                    ) : (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/movie/${result.movie_poster}`,
                          state: { movie: result },
                        }}
                        onClick={handleClick}>
                        <MovieCard movie={result} size='8.5rem' />
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none mobile d-sm-block d-md-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Search results for : {searchValue}
              </h1>
              <div className='row justify-content-start'>
                {currentSearchResults.map((result, index) => (
                  <div key={index} className='col-2 mt-2 mx-3'>
                    {result instanceof ActorModel ? (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/actor/${result.actor_id}/${result.actor_Name}`,
                          state: { actor: result },
                        }}
                        onClick={handleClick}>
                        <ActorCard actor={result} size='9rem' />
                      </Link>
                    ) : (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/movie/${result.movie_poster}`,
                          state: { movie: result },
                        }}
                        onClick={handleClick}>
                        <MovieCard movie={result} size='9rem' />
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='mobile d-sm-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Search results for : {searchValue}
              </h1>
              <div className='row justify-content-start'>
                {currentSearchResults.map((result, index) => (
                  <div key={index} className='col-2 mt-2 mx-5'>
                    {result instanceof ActorModel ? (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/actor/${result.actor_id}/${result.actor_Name}`,
                          state: { actor: result },
                        }}
                        onClick={handleClick}>
                        <ActorCard actor={result} size='9rem' />
                      </Link>
                    ) : (
                      <Link
                        className='text-decoration-none'
                        to={{
                          pathname: `/movie/${result.movie_poster}`,
                          state: { movie: result },
                        }}
                        onClick={handleClick}>
                        <MovieCard movie={result} size='9rem' />
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-5 mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
