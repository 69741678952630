import React, { useState } from "react";
import "./css/VideoPlayer.css";
import { CookieYoutubePlayer } from "./CookieYoutubePlayer";
import image from "../../../images/talretro-icon.png";

interface VideoPlayerProps {
  videoId: string;
  height: string;
  width: string;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoId, height, width
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  return (
    <div>
      {!isPlaying ? (
        <div className="thumbnail-container">
          <div className='thumbnail'>
            <img
              src={image}
              alt='Movie Poster'
            />
            <button className='play-button' onClick={handlePlayClick}></button>
          </div>
        </div>
      ) : (
        <div className='video-player'>
          <CookieYoutubePlayer videoId={videoId} height={height} width={width} />
        </div>
      )}
    </div>
  );
};
